import React from 'react';

import LayoutPanel from 'components/layout/layoutPanel';

import Link from 'components/common/Link';
import { LinkModal } from 'kh-common-components';
import { LinkAnchor } from 'components/common/LinkAnchor';
import heroWelcomeBonus from 'images/natural-gas/hero-welcomebonus.png';
import { modalContents } from 'constants/modal-content';

function SignUpNgInfo (props) {

  return (
    <LayoutPanel background={props.panelBackground}
                padding={props.panelPadding}
                {...props}
                id="signup-ng-info">
        <div className="container">
            <div className="row mer-panel align-items-center justify-content-around">
                <div className="col-10 col-sm-7 col-lg-6 order-2 order-sm-1 p-0 mer-text--size-lg">
                    <div className="content">
                        
                        <h1 className="mer-typography--headline2">Save 35% off natural gas usage charges for 12 months<sup>*</sup>, 
                        plus get a $50 welcome bonus!<sup>^</sup></h1>
                        <h6>Choose from a $50 Kmart or Bunnings gift card, account credit, or 10,000 Flybuys points.<sup>^</sup></h6>
                    
                        <div className="mer-panel__actions mer-button-group">
                            <Link text="Sign up"
                                link="/sign-up"
                                linkClass="mer-button mer-button--primary" />
                            { props.ctaLinkAnchor && 
                                <LinkAnchor 
                                    to="/service-availability#products-info" 
                                    title="View plans" 
                                    stripHash={true} 
                                    offset={-100} 
                                    className="mer-button mer-button--secondary"
                                    />
                            }
                            { !props.ctaLinkAnchor &&                             
                                <Link text="View plans"
                                link="/natural-gas#products-info"
                                linkClass="mer-button mer-button--secondary" />                            
                            }
                        </div>
                        
                        <p className="mer-typography--body2">
                            <small>
                                *Offer ends 30 April 2025. Online only. New and moving customers. &nbsp;
                                <LinkModal 
                                    modalContents={modalContents}
                                    useModal="true"
                                    displayContent="^T&Cs apply"
                                    title="Monthly Smart Saver terms & conditions"
                                    modalTitle="Monthly Smart Saver terms and conditions"
                                    modalContentName="plan_details_and_terms_2_summer"
                                    okayButtonLabel="Close"
                                />
                            </small>
                        </p>
                    
                    </div>
                </div>
                <div className="mer-panel__images hero-banner col-8 col-sm-5 order-1 order-sm-2 p-0 mt-0 mb-3 mb-sm-0">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-10 col-lg-9 text-center p-0">
                            <img src={heroWelcomeBonus} 
                                    className="mer-panel__image"
                                    alt="Welcome Bonus" />
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </LayoutPanel>
  )
}

export default SignUpNgInfo;